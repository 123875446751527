<template>
  <GameLabLayout :go-back-button="goBackButton"  :on-settings-action="onSettingsAction" :on-action="handleSave" :on-copy-gameLab="onCopyAction"
  >
    <div class="wrapper">
      <div class="left-wrapper">
        <div class="card-wrapper">
          <div class="title-card-border"></div>
          <input class="title-input w-100" :placeholder="this.$t('add_your_game_name_here')"  v-model="gameData.name" />
          <input class="description-input" :placeholder="this.$t('short_description_of_the_game')" v-model="gameData.shortDescription" />
        </div>

        <div class="card-wrapper details">
          <div class="title-card-border"></div>
          <span class="form-label mb-2">{{$t('game_explanation')}}</span>
          <textarea class="form-input explanation mb-3" :placeholder="this.$t('game_explanation')" v-model="gameData.description" />

          <span class="form-label mb-2">{{$t('they_will_win_the_test')}}</span>
          <div class="gain-row" v-for="(gain, index) in gameData.gains" :key="index">
            <img src="@/assets/images/checkboxchecked-circle.svg" />
            <input class="gain-input" :placeholder="this.$t('What_did_the_user_win')" v-model="gameData.gains[index]"/>
            <img src="@/assets/images/trashOutline.svg" @click="deleteGain(index)" />
          </div>

          <button class="add-gain-button no-text-select" @click="addGain">
            <img src="@/assets/images/plusBlue.svg" />
            <span>{{$t('add_a_gain')}}</span>
          </button>
        </div>

        <div class="card-list">
          <GameCard
              v-for="card in gameData.cards"
              :data="card"
              :card-update="handleCardUpdate"
              :on-delete="handleDelete"
              :on-change-position="changeOrder"
              :on-duplicate-question="duplicateQuestion"
              :key="card.order + forceRerender"
              :group-list="groups"
          />
        </div>

        <div class="bottom-actions">
          <div class="add-card-button no-text-select" @click="addCard">
            <img src="@/assets/images/plus.svg" />
            <span>{{$t('add_a_gain')}}</span>
          </div>
        </div>
      </div>
      <div class="right-wrapper">
        <div class="card-wrapper">
          <h3>{{$t('cards_gamelab')}}</h3>

          <div class="card-list-right">
            <draggable
                v-model="gameData.cards"
                item-key="order"
                @end="handleDragEnd"
            >
              <template  #item="{element}">
                <div class="question-block-row">
                  <img src="@/assets/images/drag.svg" />
                  <div class="question-block">
                    <div class="question-number">
                      <img src="@/assets/images/checkCircle.svg">
                      {{$t('card')}} {{ element.order }}
                    </div>
                    <div class="question-title" v-if="element.type !== ''">{{ element.type === 'swipe' ? 'Swipe' : element.type === 'vertical' ? 'Vertical Select' : 'Horizontal Select' }}</div>
                  </div>
                </div>
              </template>
            </draggable>
          </div>
        </div>
      </div>

      <LoadingOverlay :is-loading="isLoading" />
    </div>

    <GameSettingsModal
        :visible="settingsModalVisible"
        :on-hide="hideSettingsModal"
        :settings="gameData"
    />
  </GameLabLayout>
</template>

<script>
import GameLabLayout from "@/components/layouts/GameLabLayout.vue";
import draggable from "vuedraggable";
import GameCard from "@/components/GameCard.vue";
import Question from "@/components/Question.vue";
import GameSettingsModal from "@/components/modals/GameSettingsModal.vue";
import TestLayout from "@/components/layouts/TestLayout.vue";
import gameLabService from "@/service/gameLabService";
import {handleErrorResponse} from "@/utils/utils";
import {VueSpinnerCore} from "vue3-spinners";
import LoadingOverlay from "@/components/LoadingOverlay.vue";
import therapyService from "@/service/therapyService";
import TherapyLayout from "@/components/layouts/TherapyLayout.vue";
import GameLabService from "@/service/gameLabService";

export default {
  components: {
    TherapyLayout,
    LoadingOverlay,
    TestLayout,
    GameSettingsModal,
    Question,
    GameCard,
    draggable,
    GameLabLayout,
    VueSpinnerCore
  },
  data() {
    return {
      isLoading: false,
      editMode: false,
      goBackButton: {
        name: "Game Labs",
        link: "/game-labs"
      },
      settingsModalVisible: false,
      gameData: {
        name: "",
        shortDescription: "",
        description: "",
        isPublish: true,
        color: "#FFFFFF",
        avatar: null,
        gains: [""],
        cards: [],
        startAt: '',
        finishAt: '',
      },
      groups: [],
      lastOrder: 0,
      forceRerender: 0
    }
  },
  async mounted() {
    const routeParams = this.$route.params;

    await gameLabService.getGroupList()
        .then((response) => {
          const tempGroups = [];

          for (let i = 0; i < response.data.data.length; i++) {
            tempGroups.push({
              value: response.data.data[i].gameLabsGroupId,
              text: response.data.data[i].name
            });
          }
          this.groups = tempGroups;
        })
        .catch((error) => {
          handleErrorResponse(error, this.$snackbar)
        });

    if (routeParams.id !== "new" && routeParams.id !== undefined && routeParams.id !== 'undefined') {
      this.isLoading = true;
      this.editMode = true;

      gameLabService.getGameLabDetails(routeParams.id)
          .then((response) => {
            this.gameData = response.data.data;
            this.handleGamesGet();
          })
          .catch((error) => {
            handleErrorResponse(error, this.$snackbar)
          })
          .finally(() => {
            this.isLoading = false;
          });
    } else {
      this.addCard();
    }
  },
  methods: {
    handleGamesGet() {
      this.gameData.startAt = this.formatDate(this.gameData.startAt);
      this.gameData.finishAt = this.formatDate(this.gameData.finishAt);
      this.lastOrder = this.gameData.cards.length;
      this.forceRerender++;
    },
    addGain() {
      this.gameData.gains.push("");
    },
    deleteGain(index) {
      this.gameData.gains.splice(index, 1);
    },
    onSettingsAction() {
      this.settingsModalVisible = true;
    },
    hideSettingsModal() {
      this.settingsModalVisible = false;
    },
    addCard() {
      this.lastOrder++;
      this.gameData.cards.push({
        status: true,
        order: this.lastOrder,
        type: "swipe",
        options: [
          {
            name: "Option A",
            score: 0,
            attach: null,
            attachUrl: null,
          }
        ],
        startAt: '',
        finishAt: '',
      })
    },
    handleCardUpdate(data) {
      const foundCard = this.gameData.cards.find(
          (card) => card.order === data.order
      );

      if (foundCard) {
        const index = this.gameData.cards.indexOf(foundCard);
        this.gameData.cards[index] = data;
      }
    },
    handleDelete(orderId) {
      const foundCard = this.gameData.cards.find(
          (card) => card.order === orderId
      );

      if (foundCard) {
        const index = this.gameData.cards.indexOf(foundCard);
        this.gameData.cards.splice(index, 1);
      }

      this.forceRerender++;
    },
    changeOrder(dir, orderId) {
      if (this.gameData.cards.length === 1) {
        return;
      }

      if (orderId === 1 && dir === 'up') {
        return;
      }

      if (orderId === this.gameData.cards.length && dir === 'down') {
        return;
      }

      const arr = this.gameData.cards;
      const index = arr.findIndex(item => item.order === orderId);

      if (dir === 'up' && index > 0) {
        [arr[index - 1], arr[index]] = [arr[index], arr[index - 1]];

        const tempOrder = arr[index - 1].order;
        arr[index - 1].order = arr[index].order;
        arr[index].order = tempOrder;


        this.gameData.cards = [...arr];
      } else if (dir === 'down' && index < arr.length - 1) {
        [arr[index + 1], arr[index]] = [arr[index], arr[index + 1]];

        const tempOrder = arr[index + 1].order;
        arr[index + 1].order = arr[index].order;
        arr[index].order = tempOrder;

        this.gameData.cards = [...arr];
      }

      this.forceRerender++;
    },
    duplicateQuestion(orderId) {
      const foundCard = this.gameData.cards.find(
          (card) => card.order === orderId
      );

      if (foundCard) {
        const index = this.gameData.cards.indexOf(foundCard);
        const newQuestion = JSON.parse(JSON.stringify(foundCard));
        newQuestion.order = this.gameData.cards.length + 1;
        this.gameData.cards.splice(index + 1, 0, newQuestion);
      }
    },
    handleDragEnd() {
      const updatedCards = [...this.gameData.cards];

      for (let i = 0; i < updatedCards.length; i++) {
        if (updatedCards[i].order !== i + 1) {
          updatedCards[i].order = i + 1;
        }
      }

      this.gameData.cards  = updatedCards;
      this.lastOrder = this.gameData.cards.length;
      this.forceRerender++;
    },
    handleSave() {
      if (this.editMode) {
        this.onUpdate();
      } else {
        this.onAdd();
      }
    },
    onUpdate() {
      this.isLoading = true;
      gameLabService.updateGameLab(this.gameData)
          .then((response) => {
            this.$snackbar.add({
              text: "Game lab updated successfully",
              type: "success"
            });

            this.gameData = response.data.data;
            this.handleGamesGet();
          })
          .catch((error) => {
            handleErrorResponse(error, this.$snackbar)
          })
          .finally(() => {
            this.isLoading = false;
          });
    },
    onAdd() {
      this.isLoading = true;
      gameLabService.addGameLab(this.gameData)
          .then(response => {
            this.$snackbar.add({
              text: "Game lab created successfully",
              type: "success"
            });
            window.location.href = "/game/" + response.data.data.gameLabsId;
          })
          .catch((error) => {
            handleErrorResponse(error, this.$snackbar)
          })
          .finally(() => {
            this.isLoading = false;
          });
    },
    formatDate(date) {
      const selectedDate = new Date(date);
      const options = {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      };
      const formatter = new Intl.DateTimeFormat("en-GB", options);
      return formatter.format(selectedDate).replace(/\//g, '.').replace(/[/,]/g, '');
    },

    onCopyAction() {
      gameLabService.getCopy(this.$route.params.id).then((response) => {
        this.$snackbar.add({
          text: "GameLab copied successfully",
          type: "success",
        });
        window.location.href = "/game/" + response.data.data.gameLabsId;
      }).catch((error) => {
        handleErrorResponse(error, this.$snackbar);
      });
    }

  },
}
</script>

<style scoped>
.card-wrapper {
  position: relative;
  background-color: #FFFFFF;
  border: 1px solid #EAECF0;
  border-radius: 16px;
  padding: 20px 20px 20px 28px;
  display: flex;
  flex-direction: column;
}

.card-wrapper.details {
  margin-top: 20px;
}

.title-card-border {
  position: absolute;
  height: 100%;
  width: 8px;
  top: 0;
  left: 0;
  background-color: #1C3CD5;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.card-wrapper.details .title-card-border {
  background-color: #FF8E55;
}

.wrapper {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.left-wrapper {
  width: 70%;
}

.right-wrapper {
  width: 30%;
}

h3 {
  padding: 0;
  margin: 0;
  font-family: euclid_medium, sans-serif;
  font-size: 18px;
  color: #040C2D;
}

.title-input {
  border: none;
  outline: none;
  font-family: euclid_medium, sans-serif;
  font-size: 24px;
  background-color: transparent;
  color: #040C2D;
  margin-bottom: 15px;
}

.description-input {
  border: none;
  outline: none;
  font-family: euclid_medium, sans-serif;
  font-size: 16px;
  background-color: transparent;
  color: #98A2B3;
}

.title-input:focus, .description-input:focus {
  border-bottom: 1px solid #D0D5DD;
}


.bottom-actions {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
}

.add-card-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 10px 20px;
  background-color: #040C2D;
  border-radius: 12px;
  color: #FFFFFF;
  font-family: euclid_medium, sans-serif;
  font-size: 12px;
  cursor: pointer;
}

.card-list {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.card-list-right {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 15px;
}

.explanation {
  height: 140px;
}

.gain-row {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  margin-top: 10px;
  padding: 0 10px;
}

.gain-input {
  width: 100%;
  border: none;
  outline: none;
  font-family: euclid_regular, sans-serif;
  font-size: 14px;
  background-color: transparent;
  color: #040C2D;
  border-bottom: 1px solid #D0D5DD;
  padding: 5px 0;
}


.add-gain-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 8px;
  color: #4A68F8;
  margin-top: 10px;
  border: none;
  outline: none;
  background-color: transparent;
  box-sizing: border-box;
  width: fit-content;
  transition: background-color 0.2s ease-in-out;
  font-size: 14px;
}

.add-gain-button:hover {
  background-color: #f3f4ff;
}


.question-block-row {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  margin-bottom: 5px;
  cursor: grab;
}

.question-block {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  background-color: #FFFFFF;
  border: 1px solid #EAECF0;
  border-radius: 12px;
}

.question-number {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  font-family: euclid_medium, sans-serif;
  font-size: 12px;
  color: #040C2D;
}

.question-title {
  font-family: euclid_regular, sans-serif;
  font-size: 10px;
  color: #344054;
  margin-top: 10px;
}


</style>
