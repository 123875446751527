<template>
  <TherapyLayout
      :go-back-button="goBackButton"
      :on-save="handleSave"
      :on-settings-action="() => settingsModalVisible = true"
      :on-assign-action="() => assignModalVisible = true"
      :action-text="activeTab === 2 ? this.$t('save_button') : this.$t('next')"
      :on-copy-therapy="onCopyAction"
  >
    <div class="add-therapy-wrapper">
      <Transition name="slide-fade-rtl">
        <div class="sidebar" v-if="activeTab === 1 || activeTab === 2">
          <h3 class="welcome-title">{{$t('hello_user', {name: getName})}} ☀️</h3>
          <h3 class="welcome-text">{{$t('create_new_therapies')}}</h3>

          <div class="module-block" v-for="(rootModule, rootIndex) in therapyData.modules">
            <div class="module-block-header" @click="() => activeModuleCard = rootIndex">
              <div class="module-block-icon">
                <img src="@/assets/images/settingsOutline.svg" @click="() => {
                moduleSettingsIndex = rootIndex;
                moduleSettingsVisible = true;
              }"/>
                <input class="module-name-input" v-model="rootModule.name" :placeholder="this.$t('module_name')"/>
              </div>
              <img src="@/assets/images/arrowdown.svg" class="module-block-arrow" :class="{'rotate': activeModuleCard === rootIndex}"/>
            </div>


            <Transition name="slide-up">
              <div v-if="activeModuleCard === rootIndex" class="module-block-body">
                <div class="module-block-list">
                  <div
                      class="module-name-row"
                      :class="{'active': checkActiveModule(module.order)}"
                      v-for="module in rootModule.subModules"
                      @click="setActiveSubModule(module.order)"
                      :key="module.order + forceRerender"
                  >
                    <span class="module-name">{{module.name }}</span>
                    <span class="module-step">{{$t('step_count', {stepCount: module.parts.length})}}</span>
                  </div>
                </div>
                <button class="primary-button-outline w-100 mt-3" @click="() => addSubModule(rootModule)">{{$t('add_sub_module')}}</button>
              </div>
            </Transition>
          </div>

          <button class="primary-button-outline w-100 mt-3" @click="addModule()">{{ $t('add_module') }}</button>

        </div>
      </Transition>
      <div class="content" ref="content">

        <div class="therapy-tab-panel no-text-select">
          <div class="therapy-tab-pane" :class="{'active': activeTab === 0}" @click="() => changeTab(0)">
            {{$t('therapy_info_header')}}
          </div>

          <div class="therapy-tab-pane" :class="{'active': activeTab === 1}" @click="() => changeTab(1)">
            {{$t('therapy_content_header')}}
          </div>

          <div class="therapy-tab-pane" :class="{'active': activeTab === 2}" @click="() => changeTab(2)">
            {{$t('therapy_flow')}}
          </div>
        </div>

        <div class="content-wrapper" v-if="activeTab === 0">
          <div class="content-left">
            <div class="card-wrapper">
              <div class="title-card-border"></div>
              <input class="title-input" :placeholder="$t('add_your_program_name')" v-model="therapyData.name" />
              <input class="description-input" :placeholder="$t('explanation_of_the_program')" v-model="therapyData.description" />
            </div>

            <div class="card-wrapper">
              <div class="card-row no-text-select">
                <div class="row-50">
                  <span class="input-title">
                    {{ $t('expert_title') }}
                  </span>

                  <div class="dropdown" @click="toggleTherapistDropdown" v-click-outside="hideTherapistDropdown">
                    <span v-if="!therapyData.expertIds || therapyData.expertIds.length <= 0">{{ $t('select_expert') }}</span>
                    <span v-else>
                      {{$t('expert_selected_count', {expertCount: therapyData.expertIds.length})}}
                    </span>
                    <img src="@/assets/images/arrowdown.svg" />

                    <div class="dropdown-content" v-if="therapistDropdown">
                      <div class="therapist-list scroll-design">
                        <div class="therapist-item" v-for="expert in expertsData" @click="toggleExpert(expert.id)">
                          <img v-if="therapyData.expertIds && therapyData.expertIds.includes(expert.id)" class="check" src="@/assets/images/checkboxChecked.svg" />
                          <img v-else class="check" src="@/assets/images/checkbox.svg" />
                          <span>{{ expert.name }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row-50">
                  <span class="input-title">
                    {{ $t('tags_title') }}
                  </span>

                  <div class="dropdown" @click="toggleTagsDropdown" v-click-outside="hideTagsDropdown">
                    <span v-if="tagsData.filter(tag => tag.selected).length === 0">{{$t('select_tags_question')}}</span>
                    <span v-else>
                      {{$t('selected_count', {selectedCount: tagsData.filter(tag => tag.selected).length})}}
                    </span>
                    <img src="@/assets/images/arrowdown.svg" />

                    <div class="dropdown-content" v-if="tagsDropdown">
                      <div class="tags-list scroll-design">
                        <div class="therapist-item" v-for="tag in tagsData" @click="toggleTag(tag.id)">
                          <img v-if="tag.selected" class="check" src="@/assets/images/checkboxChecked.svg" />
                          <img v-else class="check" src="@/assets/images/checkbox.svg" />
                          <span>{{ tag.name }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <span class="input-title pt-4">
                {{ $t('what_people_will_learn') }}
              </span>
              <textarea rows="5" :placeholder="this.$t('please_what_people_will_learn')" v-model="therapyData.learnDescription" />
            </div>

            <div class="bottom-actions">
              <button class="dark-button" @click="changeTab(1)">
                {{ $t('continue') }}
              </button>
            </div>
          </div>

          <div class="content-right"></div>
        </div>

        <div class="content-wrapper" v-if="activeTab === 1">
          <div class="content-left">
            <div class="card-wrapper">
              <div class="title-card-border"></div>
              <input class="title-input" :placeholder="$t('add_module_name_here')" v-model="getActiveSubModule.name"/>
            </div>

            <div class="port-list">
              <div v-for="part in orderedParts">
                <Part
                    v-if="!checkIsTypeQuestion(part.content.type)"
                    :key="part.order + forceRerender"
                    :data="part"
                    :part-update="handlePartUpdate"
                    :on-delete="deletePart"
                    :on-change-position="changeOrder"
                    :on-duplicate-question="duplicatePart"
                    :test-list="testList"
                />

                <Question
                    v-else
                    :key="part.order + forceRerender"
                    :data="part"
                    :is-part-of-therapy="true"
                    :question-update="handlePartUpdate"
                    :on-delete="deletePart"
                    :on-change-position="changeOrder"
                    :on-duplicate-question="duplicatePart"
                    :test-list="testList"
                    :on-save-for-condition="handleSave"
                    :tag-list="tagsData"
                />
              </div>
            </div>

            <div class="bottom-actions">
              <button class="dark-button"  @click="addPart">
                <img src="@/assets/images/plus.svg"/>
                {{$t('add_part')}}
              </button>
            </div>
          </div>

          <div class="content-right">
            <div class="card-wrapper">
              <h3>{{ $t('module_details') }}</h3>

              <draggable
                  v-model="getActiveSubModule.parts"
                  item-key="order"
                  @end="handleDragEnd"
              >
                <template  #item="{element}" :key="element.order + forceRerender">
                  <div class="question-block-row">
                    <img src="@/assets/images/drag.svg" />
                    <div class="question-block">
                      <div class="question-number">
                        <img src="@/assets/images/checkCircle.svg">
                        Part {{ element.order }}: {{ element.name ? element.name.substr(0, 30) : '' }}
                      </div>
                    </div>
                  </div>
                </template>
              </draggable>

            </div>
          </div>
        </div>
        <div class="content-wrapper" v-if="activeTab === 2">
          <div class="content-left">
            <div class="dnd-flow" @drop="onDrop">
              <VueFlow
                  @dragover="onDragOver"
                  @dragleave="onDragLeave"
                  v-model:nodes="getActiveSubModule.conditions.nodes"
                  v-model:edges="getActiveSubModule.conditions.edges"
                  :default-edge-options="{animated: true}"
              >
                <DropzoneBackground
                    :style="{backgroundColor: isDragOver ? '#e7f3ff' : 'transparent',transition: 'background-color 0.2s ease',}"
                >
                  <p v-if="isDragOver">{{$t('drop_here')}}</p>
                </DropzoneBackground>

                <template #node-question="nodeProps">
                  <QuestionNode v-bind="nodeProps" />
                </template>

                <template #node-number-question="nodeProps">
                  <NumberQuestionNode
                      v-bind="nodeProps"
                      :on-select="handleNumberQuestionSelect"
                  />
                </template>

                <template #node-start="nodeProps">
                  <StartNode v-bind="nodeProps" />
                </template>

                <template #node-end="nodeProps">
                  <EndNode v-bind="nodeProps" />
                </template>

                <template #node-and="nodeProps">
                  <AndNode v-bind="nodeProps" :refresh-internals="refreshInternals"  />
                </template>

                <template #node-or="nodeProps">
                  <OrNode v-bind="nodeProps" :refresh-internals="refreshInternals" />
                </template>

                <template #node-answer-condition="nodeProps">
                  <AnswerConditionNode
                      v-bind="nodeProps"
                      :test-list="testList"
                      :on-select="handleAnswerConditionSelect"
                  />
                </template>

                <template #node-test-score-condition="nodeProps">
                  <TestScoreCondition
                      v-bind="nodeProps"
                      :test-list="testList"
                      :on-select="handleScoreConditionSelect"
                  />
                </template>

                <template #node-assign="nodeProps">
                  <AssignNode
                      v-bind="nodeProps"
                      :test-list="testList"
                      :on-select="handleAssignSelect"
                  />
                </template>

                <template #node-notification="nodeProps">
                  <NotificationNode
                      v-bind="nodeProps"
                      :notification-list="notificationList"
                      :notification-select="handleNotificationSelect"
                  />
                </template>

                <template #node-part="nodeProps">
                  <PartNode v-bind="nodeProps" />
                </template>
              </VueFlow>

            </div>
          </div>

          <div class="content-right">
            <div class="card-wrapper">
              <h3>{{$t('nodes')}}</h3>

              <div class="node-grid">
                <div class="node-button" :draggable="true" @dragstart="onDragStart($event, 'answer-condition')">
                  <img src="@/assets/images/testOutline.svg" />
                  <span>{{$t('answer')}}</span>
                </div>

                <div class="node-button" :draggable="true" @dragstart="onDragStart($event, 'test-score-condition')">
                  <img src="@/assets/images/medalOutline.svg">
                  <span>{{$t('score')}}</span>
                </div>


                <div class="node-button" :draggable="true" @dragstart="onDragStart($event, 'notification')">
                  <img src="@/assets/images/notificationOutline.svg">
                  <span>{{ $t('notification') }}</span>
                </div>


                <div class="node-button" :draggable="true" @dragstart="onDragStart($event, 'assign')">
                  <img src="@/assets/images/linkCircleOutline.svg">
                  <span>{{ $t('assign') }}</span>
                </div>

                <div class="node-button" :draggable="true" @dragstart="onDragStart($event, 'and')">
                  <img src="@/assets/images/commandOutline.svg">
                  <span>AND</span>
                </div>

                <div class="node-button" :draggable="true" @dragstart="onDragStart($event, 'or')">
                  <img src="@/assets/images/commandOutline.svg">
                  <span>OR</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="scroll-wrapper">
        <button class="go-button up" @click="goUp">
          <img src="@/assets/images/chevronUp.svg" />
        </button>
        <button class="go-button down" @click="goDown">
          <img src="@/assets/images/chevronDown.svg" />
        </button>
      </div>

      <LoadingOverlay :is-loading="isLoading" />
    </div>

    <TherapySettingsModal
        :visible="settingsModalVisible"
        :on-hide="hideSettingsModal"
        :settings="therapyData" />

    <ProgramTestsModal
        :visible="assignModalVisible"
        :on-hide="() => assignModalVisible = false"
        :therapy-id="therapyData?.therapyId"
        :assigned-id="therapyData.testId"
        :weight-list="therapyWeights"
        :on-select="handleAssignTestSelect"
    />

    <ModuleSettingsModal
        :visible="moduleSettingsVisible"
        :on-hide="() => {
          moduleSettingsVisible = false;
          moduleSettingsIndex = -1;
        }"
        :module="therapyData.modules[moduleSettingsIndex]"
        :on-delete-module="handleDeleteModule"
        :on-delete-sub-module="handleDeleteSubModule"
    />
  </TherapyLayout>
</template>

<script>
import TherapyLayout from "@/components/layouts/TherapyLayout.vue";
import Question from "@/components/Question.vue";
import Part from "@/components/Part.vue";
import commonService from "@/service/commonService";
import therapyService from "@/service/therapyService";
import {
  VueSpinnerCore
} from 'vue3-spinners'
import draggable from 'vuedraggable'
import testService from "@/service/testService";
import {deepClone, handleErrorResponse, isQuestion, uuidv4} from "@/utils/utils";
import TherapySettingsModal from "@/components/modals/TherapySettingsModal.vue";
import ProgramTestsModal from "@/components/modals/ProgramTestsModal.vue";
import ModuleSettingsModal from "@/components/modals/ModuleSettingsModal.vue";
import useDragAndDrop from '@/components/condition-flow/useDnD'
import { VueFlow, useVueFlow } from '@vue-flow/core'
import PartNode from "@/components/condition-flow/PartNode.vue";
import DropzoneBackground from "@/components/condition-flow/DropzoneBackground.vue";
import StartNode from "@/components/condition-flow/StartNode.vue";
import AnswerConditionNode from "@/components/condition-flow/AnswerConditionNode.vue";
import QuestionNode from "@/components/condition-flow/QuestionNode.vue";
import EndNode from "@/components/condition-flow/EndNode.vue";
import LoadingOverlay from "@/components/LoadingOverlay.vue";
import NotificationNode from "@/components/condition-flow/NotificationNode.vue";
import notificationContentService from "@/service/notificationContentService";
import AndNode from "@/components/condition-flow/AndNode.vue";
import OrNode from "@/components/condition-flow/OrNode.vue";
import {nextTick} from "vue";
import TestScoreCondition from "@/components/condition-flow/TestScoreCondition.vue";
import AssignNode from "@/components/condition-flow/AssignNode.vue";
import NumberQuestionNode from "@/components/condition-flow/NumberQuestionNode.vue";



export default {
  components: {
    NumberQuestionNode,
    AssignNode,
    TestScoreCondition,
    OrNode,
    AndNode,
    NotificationNode,
    LoadingOverlay,
    EndNode, QuestionNode, AnswerConditionNode, StartNode, DropzoneBackground, PartNode, VueFlow,
    ModuleSettingsModal,
    ProgramTestsModal,
    TherapySettingsModal,
    Part,
    Question,
    VueSpinnerCore,
    draggable,
    TherapyLayout
  },
  data() {
    return {
      isLoading: false,
      editMode: false,
      expertsDataLoading: true,
      goBackButton: {
        name: this.$t('therapies_tab'),
        link: "/therapies"
      },
      activeTab: 0,
      therapistDropdown: false,
      tagsDropdown: false,
      tagsData: [{
        id: 0,
        name: "Loading...",
        selected: false
      }],
      expertsData: [{
        id: 0,
        name: "Loading...",
        selected: false
      }],
      activeModuleCard: 0,
      activeSubModule: 1,

      settingsModalVisible: false,
      assignModalVisible: false,
      moduleSettingsVisible: false,
      moduleSettingsIndex: -1,

      therapyData: {
        name: "",
        description: "",
        shortDescription: "",
        learnDescription: "",
        expertIds: [],
        isRetry: false,
        retryTime: 0,
        startAt: null,
        finishAt: null,
        tagIds: [],
        isPublish: 1,
        type: 0,
        modules: [
          {
            name: "Education",
            subModules: [{
              name: "New Sub Module",
              order: 1,
              parts: [{
                name: "",
                status: 1,
                order: 1,
                content: {
                  type: "welcomeText",
                  settings: {
                    drawer: false,
                    required: true,
                    other: false,
                    chat: false,
                    where: []
                  },
                }
              }],
              conditions: {
                nodes: [
                  {
                    id: 'start',
                    type: 'start',
                    position: { x: 200, y: 5 },
                  },
                  {
                    id: 'end',
                    type: 'end',
                    position: { x: 400, y: 5 },
                  }
                ],
                edges: [],
                lastNodePosition: { x: 200, y: 5 },
              }
            }],
          },
        ],
      },

      lastOrder: 1,
      forceRerender: 0,

      testList: [],
      notificationList: [],
      therapyWeights: [],
    }
  },
  setup() {
    const { onConnect, addEdges, updateNode, $reset, setNodes, setEdges, updateNodeInternals } = useVueFlow();
    const { onDragOver, onDrop, onDragLeave, isDragOver } = useDragAndDrop();

    onConnect((params) => {
      const newEdge = {
        ...params,
        id: `edge-${uuidv4()}`,
      };
      addEdges([newEdge]);
    });

    const resetFlow = async () => {
      await nextTick();
      $reset();
    };


    return {
      resetFlow,
      onDragOver,
      onDrop,
      onDragLeave,
      isDragOver,
      updateNodeInternals,
      updateNode,
      setNodes,
      setEdges,
    };
  },
  async mounted() {
    const routeParams = this.$route.params;
    this.isLoading = true;

    await commonService.getTags().then((res) => {
      this.tagsData = res.data.data.tag.map((tag) => {
        return {
          id: tag.id,
          name: tag.name,
          selected: false
        }
      });
    });

    await commonService.getExperts().then((res) => {
      this.expertsData = res.data.data.users.map((user) => {
        return {
          id: user.id,
          name: user.name,
          selected: false
        }
      });

      this.expertsDataLoading = false;
    });

    await testService.getTestList().then((response) => {
      this.testList = response.data.data;
    }).catch(() => {
      this.$snackbar.add({
        text: "Fetching test list failed",
        type: "error",
      });
    });

    await notificationContentService.getNotificationContentList().then((response) => {
      this.notificationList = response.data.data;
    }).catch(() => {
      this.$snackbar.add({
        text: "Fetching notification list failed",
        type: "error",
      });
    });


    if (routeParams.id !== "new") {
      this.editMode = true;
      this.activeTab = 1;

      therapyService.getTherapyDetail(routeParams.id).then((response) => {
        this.isLoading = false;
        this.handleGetModules(response.data.data);
      }).catch(() => {
        this.$snackbar.add({
          text: "Fetching therapy failed",
          type: "error",
        });
      });
    } else {
      this.isLoading = false;
    }
  },
  watch: {
    "therapyData.description": function (newVal, oldVal) {
      if (!newVal) {
        return;
      }
      this.therapyData.shortDescription = newVal.substring(0, 100);
    },
  },
  methods: {
    goDown() {
      const container = this.$refs.content;
      if (container) {
        container.scrollTo({
          top: container.scrollHeight,
          behavior: 'smooth'
        });
      }
    },
    goUp() {
      const container = this.$refs.content;
      if (container) {
        container.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }
    },
    onDragStart(event, type) {
      const { onDragStart } = useDragAndDrop();
      onDragStart(event, type);
    },
    handleGetModules(data) {
      const tempData = data;

      if (tempData.modules.length > 0) {
        if (tempData.tags.length > 0) {
          this.tagsData.forEach(tag => {
            tempData.tags.forEach(therapyTag => {
              if (tag.id === therapyTag.tagId) {
                tag.selected = true;
              }
            })
          });
        }

        let lastOrder = 1;
        tempData.modules.forEach((module) => {
          module.subModules.forEach((subModule, index) => {
            subModule.order = lastOrder;
            lastOrder++;

            subModule.conditions?.nodes?.map(node => {
              node.position = {
                x: parseInt(node.position.x),
                y: parseInt(node.position.y),
              }
            });
          });
        });

        this.lastOrder = tempData.modules.flatMap(module => module.subModules).length;

        this.therapyData = tempData;
        this.updateNodes();
        this.forceRerender++;
      }

      if (data.type === '1') {
        therapyService.fetchTherapyWeights(data.therapyId).then((response) => {
          this.therapyWeights = response.data.data;
        }).catch(() => {
          this.$snackbar.add({
            text: "Fetching therapy weights failed",
            type: "error",
          });
        });
      }
    },
    changeTab(tab) {
      this.activeTab = tab;

      if (tab === 2) {
        this.updateNodes();
      }
    },
    toggleTherapistDropdown() {
      this.therapistDropdown = !this.therapistDropdown;
      this.tagsDropdown = false;
    },
    hideTherapistDropdown() {
      this.therapistDropdown = false;
    },
    toggleTagsDropdown() {
      this.tagsDropdown = true;
      this.therapistDropdown = false;
      event.stopPropagation();
    },
    hideTagsDropdown() {
      this.tagsDropdown = false;
    },
    hideSettingsModal() {
      this.settingsModalVisible = false;
    },
    handleAssignTestSelect(testId) {
      this.assignModalVisible = false;
      this.therapyData.testId = testId;
    },
    handleDeleteModule() {
        if (this.therapyData.modules.length === 1) {
          this.$snackbar.add({
            text: "You can't delete the last module",
            type: "warning",
          });
          return;
        }

        const tempModules = deepClone(this.therapyData.modules);

        tempModules.splice(this.moduleSettingsIndex, 1);

        this.activeModuleCard = 0;
        this.activeSubModule = tempModules[0].subModules[0].order;
        this.therapyData.modules = tempModules;
        this.moduleSettingsVisible = false;
        this.moduleSettingsIndex = -1;
    },
    handleDeleteSubModule(sub) {
      const module = this.therapyData.modules[this.moduleSettingsIndex];

      if (module.subModules.length === 1) {
        this.$snackbar.add(
            {
              text: "At least one sub module is required",
              type: "warning",
            }
        )
        return;
      }

      this.activeSubModule = module.subModules.filter(s => s !== sub)[0].order;
      this.moduleSettingsVisible = false;
      this.moduleSettingsIndex = -1;
      module.subModules = module.subModules.filter(s => s !== sub);
    },
    addPart() {
      try {
        const foundSubModuleParts = this.therapyData.modules
            .flatMap(module => module.subModules)
            .filter(subModule => subModule.order === this.activeSubModule)[0]
            .parts;

        foundSubModuleParts.push(
            {
              name: "",
              status: 1,
              order: foundSubModuleParts.length + 1,
              content: {
                type: "welcomeText",
                settings: {
                  drawer: false,
                  required: true,
                  other: false,
                  chat: false,
                  where: []
                },
              }
            }
        );
      } catch (e) {
        console.log(e);
      }
    },
    addModule() {
      this.lastOrder++;
      this.therapyData.modules.push({
        name: "New Module (" + (this.therapyData.modules.length + 1) + ")",
        subModules: [{
          name: "New Module",
          order: this.lastOrder,
          parts: [{
            name: "",
            status: 1,
            order: 1,
            content: {
              type: "welcomeText",
              settings: {
                drawer: false,
                required: true,
                other: false,
                chat: false,
                where: []
              },
            }
          }],
        }],
      });
      this.forceRerender++;
    },
    addSubModule(rootModule) {
      this.lastOrder++;
      rootModule.subModules.push({
        name: "New Sub Module (" + (rootModule.subModules.length + 1) + ")",
        order: this.lastOrder,
        parts: [{
          name: "",
          status: 1,
          order: 1,
          content: {
            type: "welcomeText",
            settings: {
              drawer: false,
              required: true,
              other: false,
              chat: false,
              where: []
            },
          },
          conditions: {
            nodes: [
              {
                id: 'start',
                type: 'start',
                position: { x: 200, y: 5 },
              },
              {
                id: 'end',
                type: 'end',
                position: { x: 400, y: 5 },
              }
            ],
            edges: [],
            lastNodePosition: { x: 200, y: 5 },
          }
        }]
      })
      this.forceRerender++;
    },
    handlePartUpdate(part) {
      const foundSubModule = this.therapyData.modules
          .flatMap(module => module.subModules)
          .filter(subModule => subModule.order === this.activeSubModule)[0];

      const foundPart = foundSubModule.parts.find(
          (p) => p.order === part.order
      );

      if (foundPart) {
        const index = foundSubModule.parts.indexOf(foundPart);
        foundSubModule.parts[index] = part;
      }
    },
    toggleTag(id) {
      if (id === 0) {
        return;
      }

      const foundTag = this.tagsData.find(
          (tag) => tag.id === id
      );

      if (foundTag) {
        foundTag.selected = !foundTag.selected;
      }

      this.therapyData.tagIds = this.tagsData.filter(tag => tag.selected).map(tag => tag.id);
    },
    toggleExpert(id) {
      if (id === 0) {
        return;
      }

      if (this.therapyData.expertIds) {
        this.therapyData.expertIds.includes(id) ? this.therapyData.expertIds.splice(this.therapyData.expertIds.indexOf(id), 1) : this.therapyData.expertIds.push(id);
      } else {
        this.therapyData.expertIds = [id];
      }

      event.stopPropagation();
    },
    handleSave() {
      if (this.activeTab === 0) {
        this.activeTab = 1;
        return;
      } else if (this.activeTab === 1) {
        this.activeTab = 2;
        return;
      }

      if (this.editMode) {
        this.updateTherapy();
      } else {
        this.addTherapy();
      }
    },
    async activateAllSubModules() {
      let originalActiveSubModule = this.activeSubModule;
      let allSubModules = this.therapyData.modules.flatMap(module => module.subModules);

      for (let subModule of allSubModules) {
        this.setActiveSubModule(subModule.order);
        this.updateNodes();
        await new Promise(resolve => setTimeout(resolve, 100));
      }

      this.setActiveSubModule(originalActiveSubModule);
      await new Promise(resolve => setTimeout(resolve, 100));
    },
    async getSaveData() {
      await this.activateAllSubModules();
      const tempData = deepClone(this.therapyData);

      tempData.modules.forEach((module) => {
        module.subModules.forEach((subModule) => {
          if (subModule.conditions?.nodes?.length > 0) {
            subModule.conditions.nodes = subModule.conditions.nodes.map(node => {
              if (!node) return null;
              if (node.type === 'answer-condition' || node.type === 'test-score-condition' || node.type === 'assign' || node.type === 'notification' || node.type === 'number-question') {
                return {
                  id: node.id,
                  type: node.type,
                  position: node.position,
                  data: {
                    condition: node?.data?.condition,
                    score: node?.data?.score,
                    testId: node?.data?.testId,
                    notificationId: node?.data?.notificationId,
                    dimensionalId: node?.data?.dimensionalId,
                    id: node?.data?.id,
                    name: node?.data?.name,
                    type: node?.data?.type,
                  },
                };
              } else {
                return {
                  id: node.id,
                  type: node.type,
                  position: node.position,
                };
              }
            });
          }

          if (subModule.conditions?.edges?.length > 0) {
            subModule.conditions.edges = subModule.conditions.edges.map(edge => {
              if (!edge) return null;
              return {
                id: edge.id,
                source: edge.source,
                target: edge.target,
                sourceHandle: edge.sourceHandle,
                targetHandle: edge.targetHandle,
                sourceType: edge.sourceNode.type,
                targetType: edge.targetNode.type,
              };
            });
          }
        });
      });

      return tempData;

    },
    async addTherapy() {
      this.isLoading = true;
      const saveData = await this.getSaveData();
      therapyService.addTherapy(saveData).then((response) => {
        this.$snackbar.add({
          text: "Program added successfully",
          type: "success",
        });
        this.isLoading = false;
        window.location.href = "/therapy/" + response.data.data.therapyId;
      }).catch((error) => {
        this.isLoading = false;
        handleErrorResponse(error, this.$snackbar);
      });
    },
    async updateTherapy() {
      this.isLoading = true;
      const saveData = await this.getSaveData();
      therapyService.updateTherapy(saveData).then((response) => {
        this.$snackbar.add({
          text: "Program updated successfully",
          type: "success",
        });
        this.handleGetModules(response.data.data);
        this.updateNodes();
        this.isLoading = false;
      }).catch((error) => {
        this.isLoading = false;
        handleErrorResponse(error, this.$snackbar);
      });
    },
    setActiveSubModule(order) {
      this.activeSubModule = order;
      this.forceRerender++;
      this.updateNodes();
    },
    checkActiveModule(order) {
      return this.activeSubModule === order;
    },
    deletePart(part) {
      const foundSubModule = this.therapyData.modules
          .flatMap(module => module.subModules)
          .filter(subModule => subModule.order === this.activeSubModule)[0];

      const foundPart = foundSubModule.parts.find(
          (p) => p.order === part
      );

      if (foundPart) {
        const index = foundSubModule.parts.indexOf(foundPart);
        foundSubModule.parts.splice(index, 1);
      }
    },
    handleDragEnd() {
      const updatedParts = [...this.getActiveSubModule.parts];

      updatedParts.forEach((part, index) => {
        part.order = index + 1;
      });

      this.therapyData.modules
          .flatMap(module => module.subModules)
          .filter(subModule => subModule.order === this.activeSubModule)[0].parts = [...updatedParts];

      this.forceRerender++;
    },
    changeOrder(dir, orderId) {
      if (this.orderedParts.length === 1) {
        return;
      }

      if (orderId === 1 && dir === 'up') {
        return;
      }

      if (orderId ===  this.orderedParts.length && dir === 'down') {
        return;
      }

      const arr = this.orderedParts;
      const index = arr.findIndex(item => item.order === orderId);

      if (dir === 'up' && index > 0) {
        // Swap the current item with the one above it
        [arr[index - 1], arr[index]] = [arr[index], arr[index - 1]];

        // Update order values
        const tempOrder = arr[index - 1].order;
        arr[index - 1].order = arr[index].order;
        arr[index].order = tempOrder;

        this.therapyData.modules
            .flatMap(module => module.subModules)
            .filter(subModule => subModule.order === this.activeSubModule)[0].parts = [...arr];
      } else if (dir === 'down' && index < arr.length - 1) {
        // Swap the current item with the one below it
        [arr[index + 1], arr[index]] = [arr[index], arr[index + 1]];

        // Update order values
        const tempOrder = arr[index + 1].order;
        arr[index + 1].order = arr[index].order;
        arr[index].order = tempOrder;

        // Update the original array to trigger reactivity
        this.therapyData.modules
            .flatMap(module => module.subModules)
            .filter(subModule => subModule.order === this.activeSubModule)[0].parts = [...arr];
      }

      this.forceRerender++;
    },
    duplicatePart(orderId) {
      const foundSubModule = this.therapyData.modules
          .flatMap(module => module.subModules)
          .filter(subModule => subModule.order === this.activeSubModule)[0];

      const foundPart = foundSubModule.parts.find(
          (p) => p.order === orderId
      );

      if (foundPart) {
        const index = foundSubModule.parts.indexOf(foundPart);
        const newPart = JSON.parse(JSON.stringify(foundPart));

        newPart.order = foundSubModule.parts.length + 1;
        foundSubModule.parts.splice(index + 1, 0, newPart);
      }

      this.handleDragEnd();

      this.forceRerender++;
    },
    checkIsTypeQuestion(type) {
      if (type === "single") {
        return true;
      } else if (type === "multiple") {
        return true;
      } else if (type === "yesNo") {
        return true;
      } else if (type === "number") {
        return true;
      } else if (type === "text") {
        return true;
      } else if (type === "grading") {
        return true;
      } else if (type === "rating") {
        return true;
      } else if (type === "dragAndDrop") {
        return true;
      } else {
        return false;
      }
    },
    updateNodes() {
      if (!this.getActiveSubModule) {
        return;
      }

      if (!this.getActiveSubModule.conditions) {
        this.getActiveSubModule.conditions = {
          nodes: [
            {
              id: 'start',
              type: 'start',
              position: {x: 200, y: 5},
            },
            {
              id: 'end',
              type: 'end',
              position: {x: 400, y: 5},
            }
          ],
          edges: [],
          lastNodePosition: {x: 200, y: 5},
        }
      }

      if (!this.getActiveSubModule.conditions?.nodes) {
        this.getActiveSubModule.conditions.nodes = [];
      }

      if (!this.getActiveSubModule.conditions?.edges) {
        this.getActiveSubModule.conditions.edges = [];
      }

      this.getActiveSubModule.conditions.lastNodePosition = {x: 200, y: 5};

      this.getActiveSubModule.parts.map((part) => {
        if (!part.questionId) {
          this.$snackbar.add({
            text: "There is unsaved changes in the therapy. Please save the therapy first.",
            type: "warning",
          });
          return;
        }

        if (this.getActiveSubModule.conditions.nodes && this.getActiveSubModule.conditions.nodes.find((node) => node.id === part.questionId)) {
          const node = this.getActiveSubModule.conditions.nodes.find((node) => node.id === part.questionId);
          node.data = {
            ...node.data,
            ...part,
          };
        } else {
          this.getActiveSubModule.conditions.lastNodePosition.y = parseInt(this.getActiveSubModule.conditions.lastNodePosition.y, 10) + 150;
          let currentPosition = {x: this.getActiveSubModule.conditions.lastNodePosition.x, y: this.getActiveSubModule.conditions.lastNodePosition.y};

          if (isQuestion(part.content.type)) {
            this.getActiveSubModule.conditions.nodes.push({
              id: part.questionId,
              type: part.content.type === 'number' || part.content.type === 'grading' || part.content.type === 'rating' ? 'number-question' : 'question',
              position: currentPosition,
              data: part,
            });
          } else {
            this.getActiveSubModule.conditions.nodes.push({
              id: part.questionId,
              type: 'part',
              label: part.name,
              position: currentPosition,
              data: part,
            });
          }
        }
      });

      this.setNodes(this.getActiveSubModule.conditions.nodes);
      this.setEdges(this.getActiveSubModule.conditions.edges);
      this.updateNodeInternals();
    },
    handleNotificationSelect(notificationId, nodeId) {
      this.updateNode(nodeId, {
        data: {
          notificationId: notificationId
        }
      });
    },
    handleAnswerConditionSelect(conditionData, nodeId) {
      this.updateNode(nodeId, { data: conditionData });
    },
    handleNumberQuestionSelect(questionData, nodeId) {
      this.updateNode(nodeId, { data: questionData });
    },
    handleScoreConditionSelect(conditionData, nodeId) {
      this.updateNode(nodeId, { data: conditionData});
    },
    handleAssignSelect(assignData, nodeId) {
      this.updateNode(nodeId, { data: assignData});
    },
    refreshInternals() {
      this.updateNodeInternals();
    },

    onCopyAction() {
      therapyService.getCopy(this.$route.params.id).then((response) => {
        this.$snackbar.add({
          text: "Therapy copied successfully",
          type: "success",
        });
        window.location.href = "/therapy/" + response.data.data.therapyId;
      }).catch((error) => {
        handleErrorResponse(error, this.$snackbar);
      });
    }

  },
  computed: {
    getName() {
      const user = JSON.parse(localStorage.getItem('user'));
      return user.name;
    },
    orderedParts() {
      try {
        return this.therapyData.modules
            .flatMap(module => module.subModules)
            .filter(subModule => subModule.order === this.activeSubModule)[0].parts.slice().sort((a, b) => a.order - b.order);
      } catch (e) {
        return [];
      }
    },
    getActiveSubModule() {
      try {
        return this.therapyData.modules
            .flatMap(module => module.subModules)
            .filter(subModule => subModule.order === this.activeSubModule)[0];
      } catch (e) {
        return {};
      }
    }
  },

}
</script>

<style scoped>
.add-therapy-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.sidebar {
  width: 20%;
  height: calc(100vh - 64px);
  background-color: #FFFFFF;
  border-right: 1px solid #EAECF0;
  padding: 20px;
}
.content {
  width: 100%;
  height: calc(100vh - 64px);
  overflow-y: auto;
  padding: 40px;
}
.welcome-title {
  font-family: euclid_medium, sans-serif;
  font-size: 28px;
  color: #101828;
  margin: 0 0 10px 0;
  padding: 0;
}

.welcome-text {
  font-family: euclid_medium, sans-serif;
  font-size: 14px;
  color: #98A2B3;
  margin: 0 0 20px 0;
  padding: 0;
}


.therapy-tab-panel {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.therapy-tab-pane {
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: euclid_medium, sans-serif;
  font-size: 14px;
  color: #667085;
  border-bottom: 2px solid transparent;
  cursor: pointer;
}

.therapy-tab-pane.active {
  border-bottom: 2px solid #4A68F8;
  color: #101828;
}

.content-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 20px;
}

.content-wrapper .content-left {
  width: 70%;
  display: flex;
  flex-direction: column;
  gap: 20px;

}

.content-wrapper .content-right {
  width: 30%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.content-wrapper .content-full {
  width: 100%;
}

.card-wrapper {
  position: relative;
  background-color: #FFFFFF;
  border: 1px solid #EAECF0;
  border-radius: 16px;
  padding: 20px 20px 20px 28px;
  display: flex;
  flex-direction: column;
}

.card-wrapper h3 {
  margin-bottom: 10px;
}

.title-card-border {
  position: absolute;
  height: 100%;
  width: 8px;
  top: 0;
  left: 0;
  background-color: #1C3CD5;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.title-input {
  border: none;
  outline: none;
  font-family: euclid_medium, sans-serif;
  font-size: 24px;
  background-color: transparent;
  color: #040C2D;
}

.description-input {
  border: none;
  outline: none;
  font-family: euclid_medium, sans-serif;
  font-size: 16px;
  background-color: transparent;
  color: #98A2B3;
  margin-top: 15px;
}

.title-input:focus, .description-input:focus {
  border-bottom: 1px solid #D0D5DD;
}

.card-row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 15px;
}

.input-title {
  font-family: euclid_medium, sans-serif;
  font-size: 14px;
  color: #010511;
  display: block;
  margin-bottom: 5px;
}

.dropdown {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  background-color: #F5F5F5;
  border-radius: 8px;
  cursor: pointer;
}

.dropdown img {
  width: 20px;
  height: 20px;
}

.dropdown .check {
  height: 18px;
  width: 18px;
  border-radius: 0;
}

.row-50 {
  width: 100%;
}

.dropdown-content {
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  background-color: #FFFFFF;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  padding: 10px;
}

.dropdown-search {
  width: 100%;
  border-radius: 24px;
  background-color: #f5f5f5;
  border: none;
  outline: none;
  padding: 10px 20px;
  font-size: 14px;
}

.therapist-list {
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-height: 200px;
  overflow-y: auto;
}


.tags-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-left: 5px;
  margin-right: 5px;
  max-height: 200px;
  overflow-y: auto;
}

.therapist-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  cursor: pointer;
  font-family: euclid_medium, sans-serif;
  font-size: 12px;
  color: #000000;
  padding: 10px;
}

.therapist-item:hover {
  background-color: #F5F5F5;
}

.therapist-item img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.bottom-actions {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
}

.port-list {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.question-block-row {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  margin-bottom: 5px;
}

.question-block {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  background-color: #FFFFFF;
  border: 1px solid #EAECF0;
  border-radius: 12px;
}

.question-number {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  font-family: euclid_medium, sans-serif;
  font-size: 12px;
  color: #040C2D;
}

h3 {
  padding: 0;
  margin: 0;
  font-family: euclid_medium, sans-serif;
  font-size: 18px;
  color: #040C2D;
}

.module-block {
  background-color: #FFFFFF;
  border: 1px solid #D0D5DD;
  border-radius: 8px;
  padding: 15px;
  margin-top: 25px;
}

.module-block-list {
  display: flex;
  flex-direction: column;
  position: relative;
}

.module-name-row::before,
.module-name-row::after {
  content: "";
  position: absolute;
  background-color: rgba(28, 60, 213, 0.4);
}

.module-name-row::before {
  top: 50%;
  left: 0;
  width: 10px;
  height: 1.5px;
  transform: translateY(-50%);
}

.module-name-row::after {
  top: 0;
  left: 0;
  width: 1.5px;
  height: 100%;
}

.module-name-row:last-child::after {
  height: 50%;
}

.module-block-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.module-block-body {
  padding-top: 20px;
}

.module-block-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.module-block-arrow {
  height: 20px;
  width: 20px;
}

.module-block-arrow.rotate {
  transform: rotate(180deg);
}

.module-name-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 15px;
  cursor: pointer;
  position: relative;
}

.module-name-row .module-name {
  font-family: euclid_medium, sans-serif;
  font-size: 12px;
  color: rgba(28, 60, 213, 0.4);
}

.module-name-row.active .module-name {
  color: #1C3CD5;
}

.module-step {
  font-size: 12px;
  color: #667085;
  white-space: nowrap;
}

.module-name-input {
  width: 100%;
  border: none;
  border-bottom: 1px solid transparent;
  outline: none;
  background-color: transparent;
  flex: 1;
  margin-right: 5px;
}

.module-name-input:focus {
  outline: none;
  border-bottom: 1px solid #D0D5DD;
}

.node-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.node-button {
  background-color: #FFFFFF;
  border: 1px solid #EAECF0;
  border-radius: 8px;
  padding: 15px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.node-button:hover {
  background-color: #F5F5F5;
}

.node-button img {
  width: 20px;
  height: 20px;
}

.node-button span {
  font-family: euclid_medium, sans-serif;
  font-size: 12px;
  color: #667085;
}


</style>
