import axios from '../axios';
import {convertBooleanValuesToNumbers} from "@/utils/utils";

const therapyService = {
    getTherapies(page, filters = []) {
        if (filters && filters.length > 0) {
            const tempFilters = filters.map((filter) => {
                return `&${filter.key}=${filter.value}`;
            }).join('');

            return axios.get(`/v1/admin/therapy/store?limit=20&page=${page}${tempFilters}`);
        } else {
            return axios.get(`/v1/admin/therapy/store?limit=20&page=${page}`);
        }
    },
    addTherapy(data) {
        data = convertBooleanValuesToNumbers(data);

        return axios.post('/v2/admin/therapy/add', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },
    updateTherapy(data) {
        data = convertBooleanValuesToNumbers(data);

        return axios.post('/v2/admin/therapy/update', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },
    getTherapyDetail(id) {
        return axios.get(`/v2/admin/therapy/getFromId/${id}`);
    },
    assignTherapy(data) {
        return axios.post('/v1/admin/therapy/assign', data);
    },
    fetchTherapyWeights(id) {
        return axios.get(`/v1/admin/therapy/weightNote/store?therapyId=${id}`);
    },
    saveWeights(data) {
        return axios.post('/v1/admin/therapy/weightNote/addOrUpdate', data);
    },

    getCopy(therapyId) {
        return axios.post('/v2/admin/therapy/copy', {
            therapyId: therapyId
        });
    },

}

export default therapyService;
