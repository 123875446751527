<template>
  <Transition name="slide-fade" appear>
    <div v-if="visible" class="modal">
      <div class="modal-content">
        <div class="modal-header">
          <span class="modal-header-txt">All Tests</span>
        </div>

        <div class="search-wrapper">
          <img src="@/assets/images/search.svg" />
          <input class="search-input" type="text" placeholder="Search" />
        </div>

        <div class="therapy-list" v-scroll-end="loadMore">
          <div class="therapy-row" v-for="item in tests" @click="() => checkTest(item)" :class="{'checked': isSelected(item.testId)}">

            <div class="therapy-left">
              <img v-if="!isSelected(item.testId)" src="@/assets/images/checkbox-circle.svg" />
              <img v-else src="@/assets/images/checkboxchecked-circle.svg" />
              <img v-if="item.avatarUrl !== null" :src="item.avatarUrl" class="therapy-image" />

              <div class="therapy-info">
                <span class="therapy-title">{{item.name}}</span>

                <div style="margin-top: 5px">
                  <span class="therapy-tag"  v-for="tag in item.tags">{{tag.name}}</span>
                </div>
              </div>
            </div>

            <div class="therapy-right">
              <img src="@/assets/images/calendarGray.svg" class="therapy-info-icon" />
              <span class="therapy-date-text">{{formatDate(item.startAt)}}</span>
             -
              <span class="therapy-date-text">{{formatDate(item.finishAt)}}</span>
            </div>


          </div>

          <div v-if="isLoading" class="loading-center">
            <VueSpinner size="32" />
          </div>

        </div>


        <button class="dark-button w-100 mt-2" @click="onSave">
          <span v-if="!addLoading">Assign</span>
          <VueSpinnerIos v-if="addLoading" size="20" height="100%" color="#fff" />
        </button>
        <div @click="this.onHide" class="close-btn">
          <img class="cross" src="../../assets/images/cross.svg" />
        </div>

      </div>
    </div>
  </Transition>
</template>

<script>
import TextInput from "@/components/TextInput.vue";
import SelectBox from "@/components/SelectBox.vue";
import {
  VueSpinner,
  VueSpinnerIos
} from 'vue3-spinners'
import therapyService from "@/service/therapyService";
import {handleErrorResponse} from "@/utils/utils";
import testService from "@/service/testService";

export default {
  components: {
    TextInput,
    SelectBox,
    VueSpinner,
    VueSpinnerIos
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    onHide: {
      type: Function,
      default: () => {}
    },
    preData: {
      type: Object,
      default: {}
    },
    type: {
      type: String,
      default: 'company'
    },
    refreshCallback: {
      type: Function,
      default: () => {}
    },
    selectedDepartment: "",
  },
  data() {
    return {
      isLoading: true,
      tests: [],
      currentPage: 1,
      lastPage: false,
      data: {},
      assignIds: [],
      addLoading: false,
    };
  },
  mounted() {
    this.loadTests();
  },
  watch: {
    preData: {
      handler: function (val) {
        this.assignIds = val.tests.map(test => test.testId);
      },
      deep: true
    }
  },
  methods: {
    checkTest(test) {
      if (!test.isPublish) {
        this.$snackbar.add({
          type: 'warning',
          text: 'Test is not published yet'
        });
        return;
      }

      if (this.assignIds.includes(test.testId)) {
        this.assignIds = this.assignIds.filter(item => item !== test.testId);
      } else {
        this.assignIds.push(test.testId);
      }
    },
    isSelected(id) {
      return this.assignIds.includes(id);
    },
    loadMore() {
      if (!this.lastPage && !this.isLoading) {
        this.isLoading = true;
        this.currentPage++;
        this.loadTests();
      }
    },
    loadTests() {
      testService.getTests(this.currentPage).then(response => {
        this.data = response.data;
        if (response.data.data.last_page === this.currentPage) {
          this.lastPage = true;
        }

        this.tests = [...this.tests, ...response.data.data.data];
        this.isLoading = false;
      }).catch(error => {
        console.log(error);
      });
    },
    onSave() {
      this.addLoading = true;

      let json = {
        testIds: this.assignIds
      };

      if (this.type === 'company') {
        json.companyId = this.preData.companyId;
      }
      else if (this.type==='department'){
        json.departmentId=this.selectedDepartment;
      }
      else {
        json.userId = this.preData.userId;
      }

      testService.assignTest(json).then(() => {
        this.$snackbar.add({
          type: 'success',
          text: 'Test assigned successfully'
        });
        this.addLoading = false;
        this.refreshCallback();
      }).catch(error => {
        this.addLoading = false;
        handleErrorResponse(error, this.$snackbar);
      });
    },
    formatDate(dateStr) {
      try {
        const date = new Date(dateStr);
        const options = {year: 'numeric', month: 'short', day: 'numeric'};
        return date.toLocaleDateString("en", options);
      } catch (e) {
        return '-';
      }
    },
  },

};
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 24px;
}

.modal-content {
  position: relative;
  background-color: white;
  width: 50%;
  height: 100%;
  border-radius: 12px;
  padding: 24px;
  max-height: calc(100vh - 48px);
  display: flex;
  flex-direction: column;
}

.modal-content::-webkit-scrollbar {
  width: 15px;
}

.modal-content::-webkit-scrollbar-track {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  background-color: #FFF;
}

.modal-content::-webkit-scrollbar-thumb {
  background-color: rgba(4, 12, 45, 0.2);
  border-radius: 10px;
  border: 5px transparent solid;
  background-clip: padding-box;
}

.modal-content::-webkit-scrollbar-thumb:hover {
  background-color: rgba(4, 12, 45, 0.4);
}

.close-btn {
  position: absolute;
  top: 24px;
  right: 24px;
  border: none;
  cursor: pointer;
  background-color: #F2F4F7;
  border-radius: 3000px;
}
.cross{
  padding: 8px;
}
.modal-header{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 0 10px 0;
  border: none;
}
.modal-header-txt{
  font-family: euclid_semi_bold, sans-serif;
  color: #040C2D;
  font-size: 18px;
  font-style: normal;
  line-height: 24px;
}

.search-wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #EAECF0;
  background-color: #F9FAFB;
  border-radius: 24px;
  margin-bottom: 20px;
}

.search-wrapper img {
  margin-left: 10px;
}

.search-input {
  border: none;
  outline: none;
  background-color: transparent;
  padding: 10px;
  width: 100%;
}

.therapy-list {
  height: 100%;
  overflow-x: auto;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.therapy-image {
  height: 80px;
  width: 120px;
  border-radius: 16px;
}

.therapy-tag {
  font-family: euclid_regular, sans-serif;
  font-size: 12px;
  color: #101828;
  background-color: #E9EAEF;
  border-radius: 8px;
  padding: 3px 12px;
  margin-right: 5px;
  margin-bottom: 15px;
}


.therapy-row {
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid transparent;
}

.therapy-row.checked {
  border: 1px solid #3DA33E;
  background-color: #f5f5f5;
  border-radius: 16px;
}

.therapy-left {
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
}

.therapy-info {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.therapy-info .therapy-title {
  font-family: euclid_medium, sans-serif;
  font-size: 14px;
  color: #101828;
}

.therapy-info .therapy-text {
  font-size: 12px;
  color: #667085;
}

.therapy-right {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
}

.therapy-right .therapy-date-text {
  font-size: 12px;
  color: #98A2B3;
  white-space: nowrap;
}

@media screen and (max-width: 768px) {
  .therapy-right {
    display: none;
  }
}

</style>
