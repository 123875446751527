<template>
  <Transition name="slide-fade-rtl" appear>
    <div v-if="visible" class="modal">
      <div class="modal-content">
        <div class="modal-header">
          <span class="modal-header-txt">Edit an Image</span>
          <span class="modal-header-description">You can edit an image here.</span>
        </div>

        <div class="select-center" v-if="!selectedImage">
          <button class="select-image" @click="openSelectImage">
            Select to start editing
          </button>
        </div>
        <div class="modal-body" ref="editorContainer" v-else></div>

        <div @click="handleHide" class="close-btn">
          <img class="cross" src="../../assets/images/cross.svg" alt="Close" />
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import {ref, onMounted, watch} from 'vue';
import FilerobotImageEditor from 'filerobot-image-editor';


export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    onHide: {
      type: Function,
      default: () => {}
    },
  },
  setup(props) {
    const editorContainer = ref(null);
    const selectedImage = ref(null);

    const downloadImage = (base64Image, fileName) => {
      const link = document.createElement('a');
      link.href = base64Image;
      link.download = fileName || 'generated.png';

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    const handleHide = () => {
      selectedImage.value = null;
      props.onHide();
    };

    const loadImageEditor = () => {
      if (editorContainer.value) {
        const { TABS, TOOLS } = FilerobotImageEditor;
        const config = {
          source: selectedImage.value,
          onSave: (editedImageObject, designState) => {
            const base64Image = editedImageObject.imageBase64;
            console.log('editedImageObject', editedImageObject);
            downloadImage(base64Image, editedImageObject.name);
          },
          onClose: () => {
            handleHide();
          },
          tabsIds: [TABS.ADJUST, TABS.ANNOTATE, TABS.WATERMARK],
          defaultTabId: TABS.ANNOTATE,
          defaultToolId: TOOLS.TEXT,
          [TOOLS.WATERMARK]: {
            gallery: [
              'https://admin.ibilityapp.com/ibility-dark.png',
            ],
            textScalingRatio: 0.33,
            imageScalingRatio: 0.33,
          }
        };

        const imageEditor = new FilerobotImageEditor(editorContainer.value, config);
        imageEditor.render();
      } else {
        setTimeout(() => {
          loadImageEditor();
        }, 500);
      }
    };

    const openSelectImage = () => {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = 'image/*';
      input.onchange = (e) => {
        const file = e.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onload = (e) => {
            selectedImage.value = e.target.result;
            loadImageEditor();
          };
          reader.readAsDataURL(file);
        }
      };
      input.click();
    };

    return {
      editorContainer,
      loadImageEditor,
      selectedImage,
      openSelectImage,
      handleHide
    };
  },
};
</script>


<style scoped>
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 24px;
  z-index: 999;
}

.modal-content {
  position: relative;
  background-color: white;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  padding: 24px;
  overflow-y: auto;
  max-height: calc(100vh - 48px);
}

.modal-header{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 0 10px 0;
  border: none;
}

.modal-header-txt{
  font-family: euclid_semi_bold, sans-serif;
  color: #040C2D;
  font-size: 18px;
  font-style: normal;
  line-height: 24px;
}

.modal-header-description {
  font-family: euclid_medium, sans-serif;
  color: #667085;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
}

.close-btn {
  position: absolute;
  top: 24px;
  right: 24px;
  background: none;
  border: none;
  cursor: pointer;
  background-color: #F2F4F7;
  border-radius: 3000px;
}
.cross{
  padding: 8px;
}

.modal-body {
  position: relative;
}

.select-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.select-image {
  background-color: #040C2D;
  color: white;
  font-family: euclid_semi_bold, sans-serif;
  font-size: 14px;
  border-radius: 8px;
  padding: 12px 24px;
  cursor: pointer;
  border: none;
  transition: background-color 0.3s;
}

.select-image:hover {
  background-color: #1C3CD5;
}
</style>

