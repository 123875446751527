<script setup>
import { Handle, Position } from '@vue-flow/core';

const props = defineProps(['id', 'data', 'notificationList', 'notificationSelect', 'editDisabled']);

const handleSelect = (event) => {
  if (event.target.value === '0') return;

  props.notificationSelect(event.target.value, props.id);
};

</script>
<template>
  <div class="node-wrapper">
    <Handle id="input" type="target" :position="Position.Top"/>
    <Handle id="output" type="source" :position="Position.Bottom"/>

    <div class="node-title-row">
      <img src="@/assets/images/notificationWhiteOutline.svg" />
      <span>Notification</span>
    </div>

    <select @change="handleSelect" :disabled="editDisabled">
      <option value="0">Select notification</option>
      <option v-for="notification in notificationList" :value="notification.notificationId" :selected="notification.notificationId === data.notificationId">
        {{notification.content?.label}}
      </option>
    </select>

  </div>
</template>

<style scoped>
.node-wrapper {
  height: 100%;
  width: 100%;
  min-width: 150px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: #101828;
  color: white;
  border: none;
  border-radius: 9px;
  box-shadow: none;
  font-size: 10px;
}

.node-wrapper.selected {
  background: #1C3CD5;
  border: none;
}

.node-title-row {
  display: flex;
  align-items: center;
  gap: 5px;
}

.node-title-row img {
  width: 14px;
  height: 14px;
}

select {
  border-radius: 8px;
  border: 1px solid #F5F5F5;
  background: transparent;
  color: #F5F5F5;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding: 5px 10px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
select::-webkit-scrollbar {
  width: 15px;
}

select::-webkit-scrollbar-track {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  background-color: #FFF;
}

select::-webkit-scrollbar-thumb {
  background-color: rgba(4, 12, 45, 0.2);
  border-radius: 10px;
  border: 5px transparent solid;
  background-clip: padding-box;
}

select::-webkit-scrollbar-thumb:hover {
  background-color: rgba(4, 12, 45, 0.4);
}

select option {
  background-color: #101828;
}

</style>
