<template>
  <TestLayout :go-back-button="goBackButton" :on-action="onHeaderAction" :on-settings-action="onSettingsAction" :on-copy-test="onCopyAction">
    <div class="root-wrapper">
      <div class="top-panel">
        <div class="tab-panel no-text-select">
          <div class="tab" :class="{'active': activeTab === 0}" @click="() => changeTab(0)">
            {{$t('questions')}}
          </div>

          <div class="tab" :class="{'active': activeTab === 1}" @click="() => changeTab(1)">
            {{$t('dimensions')}}
          </div>
        </div>

        <div class="question-select-wrapper" v-if="selectedSteps.length > 0">
          <span>{{$t('selected')}} {{selectedSteps.length}}</span>
          <ActionDropdown v-if="!dimensionSelectLoading" :actions="rootDimensionList" :on-action="handleSelectedAction" placeholder="Select a dimension" />
          <VueSpinnerIos v-if="dimensionSelectLoading" size="14"/>
        </div>

      </div>

      <div class="wrapper" v-if="activeTab === 0">
        <div  class="left-wrapper">
          <div class="card-wrapper">
            <div class="title-card-border"></div>
            <input class="title-input w-100" :placeholder="this.$t('add_your_test')" v-model="testData.name" />
            <input class="description-input" :placeholder="this.$t('explanation_test')" v-model="testData.description" />
          </div>

          <div class="question-list">
            <Question
                v-for="question in testData.questions"
                :data="question"
                :on-delete="handleDelete"
                :question-update="handleQuestionUpdate"
                :on-change-position="changeOrder"
                :on-duplicate-question="duplicateQuestion"
                :key="question.order + forceRerender"
                :tag-list="tagsData"
                :dimension-list="dimensionsData"
            />
          </div>
          <div class="bottom-actions">
            <div class="add-question-button no-text-select" @click="addQuestion">
              <img src="@/assets/images/plus.svg" />
              <span>{{$t('add_a_question')}}</span>
            </div>
          </div>
        </div>
        <div class="right-wrapper">
          <div class="card-wrapper">
            <div class="header-wrapper">
              <h3>{{$t('steps')}}</h3>

              <div class="color-tool-wrapper">
                <template v-for="dimensionKey in Object.keys(colorFilter)">
                  <div class="color-tool" :style="{backgroundColor: colorFilter[dimensionKey].color}" v-tooltip="getDimensionDame(dimensionKey)" @click="selectIds(colorFilter[dimensionKey].questions)" />
                </template>
              </div>
            </div>

            <div class="question-list-right">
              <draggable
                  v-model="testData.questions"
                  item-key="order"
                  @end="updateOrder"
              >
                <template  #item="{element}">
                  <div class="question-block-row">
                    <img src="@/assets/images/drag.svg" />
                    <div class="question-block" :class="selectedSteps.includes(element.order) && 'selected'" @click="handleToggleStep(element.order)">
                      <div class="question-indicator" :style="{backgroundColor: getColorCode(element.questionId)}" />
                      <div class="question-number">
                        <img v-if="selectedSteps.includes(element.order)" src="@/assets/images/checkCircle.svg">
                        <img v-else src="@/assets/images/checkBlank.svg">
                        {{$t('question_type_program')}} {{ element.order }}
                      </div>
                      <div class="question-title" v-if="element.name !== '' && element.name">{{element.name.substr(0, 30)}}...</div>
                    </div>
                  </div>
                </template>
              </draggable>
            </div>
          </div>
        </div>
      </div>

      <div class="wrapper" v-if="activeTab === 1">
        <div class="left-wrapper">

          <div class="dimension-list">
            <QuestionDimension
                v-for="(dimension, index) in dimensionsData"
                :key="dimension.dimensionalId"
                :data="dimension"
                :index="index"
                :on-delete="handleNewDimensionDelete"
                :request-refresh="fetchDimensions"
            />
          </div>

          <div class="bottom-actions">
            <div class="add-question-button no-text-select" @click="addNewDimension">
              <img src="@/assets/images/plus.svg" />
              <span>{{$t('add_a_dimension')}}</span>
            </div>
          </div>
        </div>

        <div class="right-wrapper">
          <div class="card-wrapper">
            <div class="header-wrapper">
              <h3>{{$t('steps')}}</h3>

              <div class="color-tool-wrapper">
                <template v-for="dimensionKey in Object.keys(colorFilter)">
                  <div class="color-tool" :style="{backgroundColor: colorFilter[dimensionKey].color}" v-tooltip="getDimensionDame(dimensionKey)" @click="selectIds(colorFilter[dimensionKey].questions)" />
                </template>
              </div>
            </div>

            <div class="question-list-right">
              <div class="question-block-row" v-for="element in testData.questions">
                <div class="question-block" :class="selectedSteps.includes(element.order) && 'selected'" @click="handleToggleStep(element.order)">
                  <div class="question-indicator" :style="{backgroundColor: getColorCode(element.questionId)}" />
                  <div class="question-number">
                    <img v-if="selectedSteps.includes(element.order)" src="@/assets/images/checkCircle.svg">
                    <img v-else src="@/assets/images/checkBlank.svg">
                    {{$t('question_type_program')}} {{ element.order }}
                  </div>
                  <div class="question-title" v-if="element.name !== ''">{{element.name.substr(0, 30)}}...</div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <LoadingOverlay :is-loading="isLoading" />
    <TestSettingsModal :visible="testSettingsVisible" :on-hide="() => testSettingsVisible = false" :settings="testData" />
  </TestLayout>
</template>

<script>
import Question from "@/components/Question.vue";
import TestLayout from "@/components/layouts/TestLayout.vue";
import testService from "@/service/testService";
import draggable from 'vuedraggable'

import {VueSpinnerCore, VueSpinnerIos} from 'vue3-spinners'
import {colorPalette, handleErrorResponse} from "@/utils/utils";
import TestSettingsModal from "@/components/modals/TestSettingsModal.vue";
import commonService from "@/service/commonService";
import LoadingOverlay from "@/components/LoadingOverlay.vue";
import testDimensionService from "@/service/testDimensionService";
import QuestionDimension from "@/components/QuestionDimension.vue";
import ActionDropdown from "@/components/ActionDropdown.vue";

export default {
  components: {
    ActionDropdown,
    LoadingOverlay,
    TestSettingsModal,
    TestLayout,
    Question,
    QuestionDimension,
    VueSpinnerCore,
    VueSpinnerIos,
    draggable
  },
  data() {
    return {
      isLoading: false,
      editMode: false,
      questions: [{
        questionNumber: "1",
        questionTitle: "",
      }],
      goBackButton: {
        name: this.$t('tests'),
        link: "/tests"
      },
      testData: {
        name: "",
        description: "",
        shortDescription: "",
        startAt: null,
        finishAt: null,
        isRetry: false,
        retryTime: 90,
        isPublish: false,
        isRandom: false,
        questions: [],
      },
      selectedSteps: [],
      dimensionSelectLoading: false,
      tagsData: [],
      dimensionsData: [],
      rootDimensionList: [
        {
          name: 'Add a dimension first',
          action: '-1'
        }
      ],
      testSettingsVisible: false,
      activeTab: 0,
      lastOrder: 0,
      forceRerender: 0,
      colorFilter: {},
    }
  },
  watch: {
    "testData.description": function (newVal, oldVal) {
      this.testData.shortDescription = newVal.substring(0, 100);
    },
  },
  mounted() {
    const routeParams = this.$route.params;

    if (routeParams.id !== "new") {
      this.isLoading = true;
      this.editMode = true;
      testService.getTestDetail(routeParams.id).then((response) => {
        this.testData = response.data.data;
        this.fetchDimensions();
        this.handleTestGet();
      }).catch(() => {
        this.$snackbar.add({
          text: "Fetching test failed",
          type: "error",
        });
      });
    } else {
      this.addQuestion();
    }
    this.fetchTags();
  },
  methods: {
    handleTestGet() {
      this.testData.startAt = this.formatDate(this.testData.startAt);
      this.testData.finishAt = this.formatDate(this.testData.finishAt);
      this.updateOrder();
      this.isLoading = false;
    },
    onSettingsAction() {
      this.testSettingsVisible = true;
    },
    addQuestion() {
      this.lastOrder++;

      this.testData.questions.push({
          name: "",
          status: 1,
          content: {
            type: "single",
            settings: {
              required: true,
              randomOrder: false,
              other: false,
              grading: 0
            },
            attach: [],
            options: [
              {
                id: 0,
                name: "Option A"
              },
              {
                id: 1,
                name: "Option B",
              },
            ]
          },
          order: this.lastOrder,
          waitingTime: null
      });
    },
    fetchTags() {
      commonService.getTags().then((res) => {
        this.tagsData = res.data.data.tag.map((tag) => {
          return {
            id: tag.id,
            name: tag.name,
          }
        });
      });
    },
    async fetchDimensions() {
      if (!this.testData.testId) {
        return;
      }

      await testDimensionService.getDimensions(this.testData.testId).then((res) => {
        this.dimensionsData = res.data.data;
        this.rootDimensionList = this.dimensionsData.filter((dimension) => dimension.type === 'root').map((dimension, index) => {
          this.colorFilter[dimension.dimensionalId] = {
            questions: dimension?.questions,
            color: colorPalette[index]
          };

          return {
            name: dimension.name,
            action: dimension.dimensionalId
          }
        });

        if (this.dimensionsData.length === 0) {
          this.rootDimensionList = [
            {
              name: 'Add a dimension first',
              action: '-1'
            }
          ];
        }
      }).catch(error => {
        handleErrorResponse(error, this.$snackbar);
      });
    },
    handleDelete(orderId) {
      const foundQuestion = this.testData.questions.find(
          (question) => question.order === orderId
      );

      if (foundQuestion) {
        const index = this.testData.questions.indexOf(foundQuestion);
        this.testData.questions.splice(index, 1);
      }

      this.updateOrder();
    },
    onHeaderAction(id) {
      if (id === 2) {
        if (this.editMode) {
          this.updateTest();
        } else {
          this.addTest();
        }
      }
    },
    handleQuestionUpdate(data) {
      const foundQuestion = this.testData.questions.find(
          (question) => question.order === data.order
      );

      if (foundQuestion) {
        const index = this.testData.questions.indexOf(foundQuestion);
        this.testData.questions[index] = data;
      }
    },
    addTest() {
      this.isLoading = true;
      testService.addTest(this.testData).then((response) => {
        this.$snackbar.add({
          text: "Test added successfully",
          type: "success",
        });
        window.location.href = "/test/" + response.data.data.testId;
      }).catch((error) => {
        this.isLoading = false;
        handleErrorResponse(error, this.$snackbar);
      });
    },
    addNewDimension() {
      if (!this.testData.testId) {
        this.$snackbar.add({
          text: "Please save the test first",
          type: "warning",
        });
        return;
      }

      this.dimensionsData.push({
        isNew: true,
        type: 'root',
        testId: this.testData.testId,
        name: '',
        description: '',
        shortDescription: '',
        strengthsExplanation: '',
        developmentAreasExplanation: '',
        score: 0
      });
    },
    updateTest() {
      this.isLoading = true;
      testService.updateTest(this.testData).then((response) => {
        this.isLoading = false;
        this.$snackbar.add({
          text: "Test updated successfully",
          type: "success",
        });
        this.testData = response.data.data;
        this.handleTestGet();
      }).catch((error) => {
        this.isLoading = false;
        handleErrorResponse(error, this.$snackbar);
      });
    },
    updateOrder() {
      const updatedQuestions = [...this.testData.questions];

      updatedQuestions.forEach((question, index) => {
        question.order = index + 1;
      });

      this.testData.questions = updatedQuestions;
      this.lastOrder = this.testData.questions.length;
      this.forceRerender++;
    },
    changeOrder(dir, orderId) {
      if (this.testData.questions.length === 1) {
        return;
      }

      if (orderId === 1 && dir === 'up') {
        return;
      }

      if (orderId === this.testData.questions.length && dir === 'down') {
        return;
      }

      const arr = this.testData.questions;
      const index = arr.findIndex(item => item.order === orderId);

      if (dir === 'up' && index > 0) {
        // Swap the current item with the one above it
        [arr[index - 1], arr[index]] = [arr[index], arr[index - 1]];

        // Update order values
        const tempOrder = arr[index - 1].order;
        arr[index - 1].order = arr[index].order;
        arr[index].order = tempOrder;


        this.testData.questions = [...arr];
      } else if (dir === 'down' && index < arr.length - 1) {
        // Swap the current item with the one below it
        [arr[index + 1], arr[index]] = [arr[index], arr[index + 1]];

        // Update order values
        const tempOrder = arr[index + 1].order;
        arr[index + 1].order = arr[index].order;
        arr[index].order = tempOrder;

        // Update the original array to trigger reactivity
        this.testData.questions = [...arr];
      }

      this.forceRerender++;
    },
    duplicateQuestion(orderId) {
      const foundQuestion = this.testData.questions.find(
          (question) => question.order === orderId
      );

      if (foundQuestion) {
        const index = this.testData.questions.indexOf(foundQuestion);
        const newQuestion = JSON.parse(JSON.stringify(foundQuestion));
        if (newQuestion.questionId) {
          delete newQuestion.questionId;
        }
        newQuestion.order = this.testData.questions.length + 1;
        this.testData.questions.splice(index + 1, 0, newQuestion);
      }

      this.updateOrder();
    },
    formatDate(date) {
      const selectedDate = new Date(date);
      const options = {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      };
      const formatter = new Intl.DateTimeFormat("en-GB", options);
      return formatter.format(selectedDate).replace(/\//g, '.').replace(/[/,]/g, '');
    },
    handleToggleStep(order) {
      if (this.selectedSteps.includes(order)) {
        this.selectedSteps = this.selectedSteps.filter((step) => step !== order);
      } else {
        this.selectedSteps.push(order);
      }
    },
    changeTab(tab) {
      this.activeTab = tab;
    },
    handleSelectedAction(action) {
      if (!this.testData.testId) {
        this.$snackbar.add({
          text: "Please save the test first",
          type: "warning",
        });
        return;
      }

      if (action === '-1') {
        this.$snackbar.add({
          text: "Please add a dimension first",
          type: "warning",
        });
        return;
      }

      this.dimensionSelectLoading = true;
      const tempIds = [];

      this.selectedSteps.forEach((step) => {
        const foundQuestion = this.testData.questions.find(
            (question) => question.order === step
        );

        if (foundQuestion) {
          tempIds.push(foundQuestion.questionId);
        }
      });

      testDimensionService.setSelectedQuestions({
        testId: this.testData.testId,
        dimensionalId: action,
        questionIds: tempIds
      }).then(async () => {
        await this.fetchDimensions();
        this.$snackbar.add({
          text: "Questions added to dimension successfully",
          type: "success",
        });
        this.dimensionSelectLoading = false;
        this.selectedSteps = [];
      }).catch((error) => {
        this.dimensionSelectLoading = false;
        handleErrorResponse(error, this.$snackbar);
      });
    },
    handleNewDimensionDelete(data) {
      if (data.isNew) {
        const index = this.dimensionsData.indexOf(data);
        this.dimensionsData.splice(index, 1);
      }
    },
    getColorCode(questionId) {
      for (const dimensionId in this.colorFilter) {
        if (this.colorFilter.hasOwnProperty(dimensionId)) {
          const dimension = this.colorFilter[dimensionId];
          if (dimension.questions.includes(questionId)) {
            return dimension.color;
          }
        }
      }
      return '#F5F5F5';
    },
    getDimensionDame(dimensionId) {
      const dimension = this.dimensionsData.find((dimension) => dimension.dimensionalId === dimensionId);
      return dimension ? dimension.name : '';
    },
    selectIds(ids) {
      if (!ids && ids?.length <= 0) {
        return;
      }

      const tempIds = [];

      ids.map(id => {
        // find the question
        const question = this.testData.questions.find(question => question.questionId === id);
        if (question) {
          tempIds.push(question.order);
        }
      });

      this.selectedSteps = tempIds;

    },
    onCopyAction() {
      testService.getCopy(this.$route.params.id).then((response) => {
        this.$snackbar.add({
          text: "Test copied successfully",
          type: "success",
        });
        window.location.href = "/test/" + response.data.data.testId;
      }).catch((error) => {
        handleErrorResponse(error, this.$snackbar);
      });
    }
  },
}
</script>

<style scoped>
.card-wrapper {
  position: relative;
  background-color: #FFFFFF;
  border: 1px solid #EAECF0;
  border-radius: 16px;
  padding: 20px 20px 20px 28px;
  display: flex;
  flex-direction: column;
}

.title-card-border {
  position: absolute;
  height: 100%;
  width: 8px;
  top: 0;
  left: 0;
  background-color: #1C3CD5;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.wrapper {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.wrapper-column {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.left-wrapper {
  width: 70%;
}

.right-wrapper {
  width: 30%;
}

h3 {
  padding: 0;
  margin: 0;
  font-family: euclid_medium, sans-serif;
  font-size: 18px;
  color: #040C2D;
}

.title-input {
  border: none;
  outline: none;
  font-family: euclid_medium, sans-serif;
  font-size: 24px;
  background-color: transparent;
  color: #040C2D;
  margin-bottom: 15px;
}

.description-input {
  border: none;
  outline: none;
  font-family: euclid_medium, sans-serif;
  font-size: 16px;
  background-color: transparent;
  color: #98A2B3;
}

.title-input:focus, .description-input:focus {
  border-bottom: 1px solid #D0D5DD;
}

.question-list {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.dimension-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.bottom-actions {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
}

.add-question-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 10px 20px;
  background-color: #040C2D;
  border-radius: 12px;
  color: #FFFFFF;
  font-family: euclid_medium, sans-serif;
  font-size: 12px;
  cursor: pointer;
}

.question-block-row {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  margin-bottom: 5px;
  cursor: grab;
}

.question-block {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 10px 10px 22px;
  background-color: #FFFFFF;
  border: 1px solid #EAECF0;
  border-radius: 12px;
  position: relative;
}

.question-block.selected {
  border: 1px solid #1C3CD5;
}

.question-indicator {
  position: absolute;
  height: 100%;
  width: 12px;
  top: 0;
  left: 0;
  overflow: hidden;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.question-number {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  font-family: euclid_medium, sans-serif;
  font-size: 12px;
  color: #040C2D;
}

.question-title {
  font-family: euclid_regular, sans-serif;
  font-size: 10px;
  color: #344054;
  margin-top: 10px;
}

.question-list-right {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 15px;
}

.tab-panel {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 20px;
}

.tab {
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: euclid_medium, sans-serif;
  font-size: 14px;
  color: #667085;
  border-bottom: 2px solid transparent;
  cursor: pointer;
}

.tab.active {
  border-bottom: 2px solid #4A68F8;
  color: #101828;
}

.top-panel {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.question-select-wrapper {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.question-select-wrapper span {
  font-family: euclid_medium, sans-serif;
  font-size: 12px;
  color: #040C2D;
}

.color-tool {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: help;
}

.color-tool-wrapper {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.header-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 768px) {
  .wrapper {
    flex-direction: column;
  }

  .left-wrapper {
    width: 100%;
  }

  .right-wrapper {
    display: none;
    width: 100%;
  }
}


</style>
