<template>
  <Transition name="slide-fade" appear>
    <div v-if="visible" class="modal">
      <div class="modal-content">
        <div class="modal-step-1">
          <div class="modal-header">
            <span class="modal-header-txt" v-if="!editMode">{{$t('add_new_user')}}</span>
            <span class="modal-header-txt" v-else>{{$t('edit_user')}}</span>
          </div>
          <div class="upload-image-main-div">

            <FileDrop :on-file-selected="onFileSelect" :selected-file="user.avatarUrl"/>

            <div class="upload-image-div">
              <span class="header-span">{{$t('image')}}</span>
              <div class="upload-image-div">
                <span class="upload-image-txt">{{$t('explanation')}}</span>
                <span class="upload-image-txt">{{$t('recommended_format')}}</span>
              </div>
            </div>


          </div>
          <div class="forms">
            <div class="double-form">
              <div class="form-div">
                <span class="form-title">{{$t('name_expert')}}</span>
                <input type="text" class="form-input" :placeholder="this.$t('enter_name')" v-model="user.name" />
              </div>
              <div class="form-div">
                <span class="form-title">{{$t('surname_person')}}</span>
                <input type="text" class="form-input" :placeholder="this.$t('enter_surname')" v-model="user.surname" />
              </div>

            </div>
            <div class="double-form">
              <div class="form-div">
                <span class="form-title">{{$t('email_address')}}</span>
                <input type="text" class="form-input" :placeholder="this.$t('enter_email')" v-model="user.email" />
              </div>
              <div class="form-div">
                <span class="form-title">{{$t('mobile_phone_number')}}</span>
                <input type="text" class="form-input" :placeholder="this.$t('enter_phone_number')" v-model="user.phoneNumber" />
              </div>
            </div>
            <SelectBox class="select-box" :options="selectboxOptions" :on-select="handleRole" :selected-value="user.roleId" />

          </div>
          <div class="btn-div2">
            <div></div>
            <div class="btn-style" @click="onUserAction">
              <span class="next-txt" v-if="!isLoading && !editMode">{{$t('add_user')}}</span>
              <span class="next-txt" v-if="!isLoading && editMode">{{$t('save_button')}}</span>
              <VueSpinnerIos v-if="isLoading" size="20" height="100%" color="#fff" />
            </div>
          </div>
          <div @click="this.onHide" class="close-btn">
            <img class="cross" src="../../assets/images/cross.svg" />
          </div>
        </div>
      </div>
      <SuccessModal :message="successMessage" :is-visible="showSuccessModal" :on-close="onSuccessModalClose" :on-action="onSuccessModalClose" />
    </div>
  </Transition>
</template>

<script>
import TextInput from "@/components/TextInput.vue";
import SelectBox from "@/components/SelectBox.vue";
import SuccessModal from "@/components/modals/SuccessModal.vue";
import FileDrop from "@/components/FileDrop.vue";
import userService from "@/service/userService";
import {
  VueSpinnerIos
} from 'vue3-spinners'
import {handleErrorResponse} from "@/utils/utils";

export default {
  components: {
    FileDrop,
    SuccessModal,
    TextInput,
    SelectBox,
    VueSpinnerIos
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    editMode: {
      type: Boolean,
      default: false
    },
    editData: {
      type: Object,
      default: {
        status: 1,
        name: '',
        surname: '',
        email: '',
        phoneNumber: '',
        role: 'USER',
        avatarUrl: ''
      }
    },
    roleList: {
      type: Array,
      default: []
    },
    onHide: {
      type: Function,
      default: () => {}
    },
    refreshCallback: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      isModalVisible: false,
      showSuccessModal: false,
      uploadFile: null,
      isLoading: false,
      user: {
        status: 1,
        name: '',
        surname: '',
        email: '',
        phoneNumber: '',
        roleId: '',
        avatarUrl: ''
      },
      selectboxOptions: [],
      successMessage: 'User added successfully'
    };
  },
  watch: {
    editData: {
      handler() {
        if (this.editMode) {
          this.successMessage = 'User updated successfully';
          this.user = {
            userId: this.editData.userId,
            status: this.editData.status,
            name: this.editData.name,
            surname: this.editData.surname,
            email: this.editData.email,
            phoneNumber: this.editData.phone,
            roleId: this.editData.roleId,
            avatarUrl: this.editData.avatarUrl
          }
        }
      },
      deep: true,
    },
    roleList: {
      handler() {
        this.selectboxOptions = this.roleList.map(role => ({
          text: role.name,
          value: role.id,
        }));
      },
      deep: true,
    }
  },
  mounted() {
    this.selectboxOptions = this.roleList.map(role => ({
      text: role.name,
      value: role.id,
    }));
  },
  methods: {
    showModal() {
      this.isModalVisible = true;
    },
    onClose() {
      this.isModalVisible = false;
    },
    onSuccessModalClose() {
      this.showSuccessModal = false;
      this.onHide();
    },
    showSuccess() {
      this.showSuccessModal = true;
      event.stopPropagation();
    },
    onFileSelect(file) {
      this.uploadFile = file;
    },
    onAddUser() {
      this.isLoading = true;

      const formData = new FormData();

      Object.entries(this.user).forEach(([key, value]) => {
        formData.append(key, value);
      });

      if (this.uploadFile) {
        formData.append('avatar', this.uploadFile);
      }


      userService.addUser(formData).then(() => {
        this.showSuccess();
        this.refreshCallback();
        this.isLoading = false;

        this.user = {
          status: 1,
          name: '',
          surname: '',
          email: '',
          phoneNumber: '',
          roleId: '',
          avatarUrl: ''
        };
      }).catch((error) => {
        handleErrorResponse(error, this.$snackbar);
        this.isLoading = false;
      });
    },
    onEditUser() {
      this.isLoading = true;

      const formData = new FormData();

      Object.entries(this.user).forEach(([key, value]) => {
        formData.append(key, value);
      });

      if (this.uploadFile) {
        formData.append('avatar', this.uploadFile);
      }

      userService.editUser(formData).then(() => {
        this.refreshCallback();
        this.isLoading = false;

        this.$snackbar.add({
          type: 'success',
          text: 'User updated successfully'
        });
      }).catch((error) => {
        handleErrorResponse(error, this.$snackbar);
        this.isLoading = false;
      });
    },
    onUserAction() {
      if (this.editMode) {
        this.onEditUser();
      } else {
        this.onAddUser();
      }
    },
    handleRole(value) {
      this.user.roleId = value;
    },
  },

};
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 24px;
}

.modal-content {
  position: relative;
  background-color: white;
  width: 50%;
  height: 100%;
  border-radius: 12px;
  padding: 24px;
  overflow-y: auto;
  max-height: calc(100vh - 48px);
}

.modal-content::-webkit-scrollbar {
  width: 15px;
}

.modal-content::-webkit-scrollbar-track {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  background-color: #FFF;
}

.modal-content::-webkit-scrollbar-thumb {
  background-color: rgba(4, 12, 45, 0.2);
  border-radius: 10px;
  border: 5px transparent solid;
  background-clip: padding-box;
}

.modal-content::-webkit-scrollbar-thumb:hover {
  background-color: rgba(4, 12, 45, 0.4);
}

.close-btn {
  position: absolute;
  top: 24px;
  right: 24px;
  background: none;
  border: none;
  cursor: pointer;
  background-color: #F2F4F7;
  border-radius: 3000px;
}

.cross {
  padding: 8px;
}

.modal-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 0 10px 0;
  border: none;
}

.modal-header-txt {
  font-family: euclid_semi_bold, sans-serif;
  color: #040C2D;
  font-size: 18px;
  font-style: normal;
  line-height: 24px;
}

.upload-image-main-div {
  display: flex;
  flex-direction: row;
  gap: 40px;
  margin-top: 24px;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  width: 100%;
  height: auto;
}

.upload-image-div {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  width: 100%;
}

.header-span {
  font-family: euclid_semi_bold, sans-serif;
  color: #040C2D;
  font-size: 16px;
  line-height: 24px;
}

.upload-image-txt {
  color: #667085;
  font-size: 14px;
  line-height: 20px;
}

.forms {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 24px;
  align-items: flex-start;
  width: 100%;
}

.double-form {
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: flex-start;
  width: 100%;
}

.form-div {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  width: 100%;
}

.form-title {
  color: #010511;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
}

.btn-style {
  display: flex;
  border-radius: 24px;
  background: #040C2D;
  justify-content: center;
  align-items: center;
  width: 20%;
  height: 48px;
  cursor: pointer;
}

.next-txt {
  font-family: euclid_medium, sans-serif;
  color: #FFF;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
}

.btn-div2 {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  bottom: 30px;
  left: 24px;
  right: 24px;
}

.btn-div2 {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  bottom: 30px;
  left: 24px;
  right: 24px;
}

.select-box {
  width: 100%;
}

@media (max-width: 678px) {
  .upload-image-main-div {
    flex-direction: column;
    gap: 10px;
  }
}
</style>
